<template>
  <Popover
    :key="key"
    as="div"
    @mouseleave="clearTimer"
    @click="hideMenu"
  >
    <PopoverButton
      ref="menuButtonRef"
      :class="[
        'inline-flex justify-center gap-x-[11px] font-semibold items-center text-green-evergreen uppercase text-xs focus:outline-none'
      ]"
      :aria-label="category?.name"
      @click="goTo(category)"
    >
      <nuxt-link
        :class="[
          menuIsShown ? 'text-primary' : '',
          'font-bold leading-[1]',
          category?.name === 'Товари для військових' ||
            category?.name === 'Товары для военных'
            ? 'text-primary'
            : ''
        ]"
        class="hover:text-primary px-[15px] py-[16px]"
        :to="
          ProductVariantEntity.buildCatalogCategoryLink({
            categorySlug: category.slug,
            hostname: category?.channelHostname,
            locale
          })
        "
        @mouseover="startTimer"
      >
        {{ category?.name }}
      </nuxt-link>
    </PopoverButton>
    <div v-show="menuIsShown">
      <PopoverPanel
        ref="menuItemsRef"
        static
        :class="['menu']"
        :unmount="false"
        @mouseleave="onMenuMouseLeave"
      >
        <div class="container flex gap-4 overflow-hidden">
          <ul
            v-if="category.relations?.length"
            class="whitespace-nowrap flex flex-col"
          >
            <li
              :class="[
                'option',
                selectedSubcategory === 'all' ? 'option--active' : ''
              ]"
              @mouseover="selectSubcategory('all')"
              @click.prevent.stop="() => {}"
            >
              {{ $t('Всі товари') }}
              <ChevronRightIcon class="h-3 ml-auto" />
            </li>
            <template
              v-for="relation in category.relations?.filter(
                ({ type }) => type === 'gear'
              )"
              :key="relation.child.slug"
            >
              <li
                :class="[
                  'option',
                  relation.child.id === selectedSubcategory
                    ? 'option--active'
                    : ''
                ]"
                @mouseover="selectSubcategory(relation.child.id)"
                @click.prevent.stop="() => {}"
              >
                {{ relation.child?.name }}
                <ChevronRightIcon class="h-3 ml-auto" />
              </li>
            </template>
          </ul>
          <AppHeaderCategoryMenuChildren
            :show="selectedSubcategory === 'all'"
            :children="category.children"
            class="flex-1 max-h-full overflow-y-auto"
          />
          <template
            v-for="relation in category.relations?.filter(
              ({ type }) => type === 'gear'
            )"
            :key="'children-' + relation.child.id"
          >
            <AppHeaderCategoryMenuChildren
              :show="selectedSubcategory === relation.child.id"
              :children="relation.children"
              :relation-slug="relation.child.slug"
              class="flex-1 max-h-full overflow-y-auto"
            />
          </template>

          <div class="banner">
            <nuxt-img
              v-if="props.banner?.imagePortrait"
              :src="props.banner.imagePortrait"
              format="webp"
              :alt="props.banner?.name || 'banner'"
              loading="lazy"
              class="h-full max-w-[210px] w-full object-contain"
              @click="onBannerClick(props.banner)"
            ></nuxt-img>
          </div>
        </div>
        <AppHeaderCategoryMenuBrands
          :is-shown="menuIsShown"
          :category-id="props.categoryId"
          class="mt-auto w-full min-h-[48px]"
        />
      </PopoverPanel>
    </div>

    <AppBackdrop :show="menuIsShown" />
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { navigateTo } from 'nuxt/app'
import ProductVariantEntity from '~/entities/ProductVariant.entity'
import { useMenuStore } from '~/stores/menu'
import TaxonEntity from '~~/entities/Taxon.entity'
const { $gtagEvent } = useNuxtApp()

const { locale } = useI18n()
const props = defineProps({
  categoryId: {
    type: Number,
    default: null
  },
  showAll: {
    type: Boolean,
    default: false
  },
  banner: {
    type: Object,
    default: () => {}
  },
  isShown: {
    type: Boolean,
    default: false
  }
})

let timer

const startTimer = () => {
  if (props.isShown) {
    menuIsShown.value = true
  }
  timer = setTimeout(() => {
    menuIsShown.value = true
  }, 400)
}

const clearTimer = () => {
  clearTimeout(timer)
  hideMenu()
}

const menuIsShown = ref(false)

const hideMenu = () => {
  menuIsShown.value = false
}

const onBannerClick = banner => {
  $gtagEvent.bannerClick(banner?.url)
  setTimeout(() => {
    return banner?.url && navigateTo(banner?.url, { external: true })
  }, 3000)
}

const category = computed(
  () => new TaxonEntity(useMenuStore().findCategoryById(props.categoryId))
)

provide('categorySlug', category.value.slug)

const selectedSubcategory = ref('all')
const menuItemsRef = ref(null)
const menuButtonRef = ref(null)

const selectSubcategory = subcategoryIndex => {
  selectedSubcategory.value = subcategoryIndex
}

const onMenuMouseLeave = () => {
  selectedSubcategory.value = 'all'
}

const key = ref('menu-desktop')

const goTo = target => {
  hideMenu()
  const isExternal =
    target?.channelHostname &&
    target?.channelHostname !== useRequestURL().hostname
  navigateTo(
    ProductVariantEntity.buildCatalogCategoryLink({
      categorySlug: target.slug,
      hostname: target?.channelHostname,
      locale: locale.value
    }),
    { external: isExternal }
  )
}

watch(
  () => useRoute().path,
  () => {
    // key.value = Date.now()
    setTimeout(() => {
      hideMenu()
    }, 0)
  }
)
</script>

<style lang="postcss" scoped>
.menu {
  @apply absolute flex flex-col z-10 origin-top-right rounded-md rounded-t-none bg-white focus:outline-none gap-10 pt-[30px] left-0 right-0 min-w-full overflow-hidden;

  height: calc(100vh - 10% - 185px);
}
.banner {
  @apply max-w-[240px] w-[210px] overflow-hidden;
}
.banner::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.banner {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.option {
  @apply flex gap-2 items-center  hover:cursor-pointer  p-2.5
    /* padding: 9px 10px;
    justify-content: space-between;
    align-items: center; */;
}

.option--active {
  @apply bg-gray rounded-[5px] text-green-ibis;
}
.option--active svg {
  @apply text-primary;
}
</style>
