<template>
  <Popover
    :key="key"
    as="div"
    class="text-left !outline-none :focus-visible:!outline-none no-focus"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <PopoverButton
      :class="[
        'relative inline-flex font-bold justify-center gap-x-[11px] items-center text-green-evergreen uppercase text-xs hover:text-primary h-[40px] pl-[1.5rem]',
        open ? 'text-primary' : '',
        ''
      ]"
      :style="{ paddingRight: '17px', paddingLeft: '20px' }"
      :aria-label="$t('Меню')"
      @click="toggleMenu"
    >
      <IconsMenuOpened
        v-if="open"
        class="absolute left-0 max-w-3.5 max-h-3.5"
      />
      <IconsMenuClosed v-else class="absolute left-0 max-w-3.5 max-h-3.5" />
      <span>{{ $t('Меню') }}</span>
    </PopoverButton>
    <!-- <app-transitions-dropdown> -->
    <div v-show="open">
      <PopoverPanel
        static
        class="absolute bg-white left-0 right-0 z-10 origin-top-right rounded-md rounded-t-none"
      >
        <div
          class="container focus:outline-none flex justify-start gap-10 py-[30px]"
        >
          <ul class="text-sm flex flex-col gap-5">
            <li class="text-[15px] font-bold">
              {{ $t('Підтримка') }}
            </li>
            <template v-for="menu in supportMenu" :key="menu.name">
              <li class="hover:text-primary">
                <NuxtLink :to="localePath(menu.href)">
                  {{ $t(menu.name) }}
                </NuxtLink>
              </li>
            </template>
          </ul>
          <ul class="text-sm flex flex-col gap-5">
            <li class="text-[15px] font-bold">
              {{ $t('Cервіси ІБІС') }}
            </li>
            <template v-for="menu in serviceIbisMenu" :key="menu.name">
              <li class="hover:text-primary">
                <NuxtLink v-if="!menu.external" :to="localePath(menu.href)">
                  {{ $t(menu.name) }}
                  <span
                    v-if="menu.description"
                    class="text-sm text-gray-mud"
                  ><br />{{ menu.description }}</span>
                </NuxtLink>
                <a
                  v-else
                  :href="menu.href"
                  rel="nofollow"
                  target="_blank"
                >
                  {{ $t(menu.name) }}
                  <span
                    v-if="menu.description"
                    class="text-sm text-gray-mud"
                  ><br />{{ menu.description }}</span>
                </a>
              </li>
            </template>
          </ul>
          <ul class="text-sm flex flex-col gap-5">
            <li class="text-[15px] font-bold">
              {{ $t('Дилери в регіонах') }}
            </li>
            <template v-for="menu in dealerIbisMenu" :key="menu.name">
              <li class="hover:text-primary">
                <NuxtLink v-if="!menu.external" :to="localePath(menu.href)">
                  {{ $t(menu.name) }}
                  <span
                    v-if="menu.description"
                    class="text-sm text-gray-mud"
                  ><br />{{ menu.description }}</span>
                </NuxtLink>
                <a
                  v-else
                  :href="menu.href"
                  rel="nofollow"
                  target="_blank"
                >
                  {{ $t(menu.name) }}
                  <span
                    v-if="menu.description"
                    class="text-sm text-gray-mud"
                  ><br />{{ menu.description }}</span>
                </a>
              </li>
            </template>
          </ul>
          <ul class="text-sm flex flex-col gap-5">
            <li class="text-[15px] font-bold">
              {{ $t('Про ІБІС') }}
            </li>
            <template v-for="menu in aboutIbisMenu" :key="menu.name">
              <li class="hover:text-primary">
                <NuxtLink v-if="!menu.external" :to="localePath(menu.href)">
                  {{ $t(menu.name) }}
                  <span
                    v-if="menu.description"
                    class="text-sm text-gray-mud"
                  ><br />{{ menu.description }}</span>
                </NuxtLink>
                <a
                  v-else
                  :href="menu.href"
                  rel="nofollow"
                  target="_blank"
                >
                  {{ $t(menu.name) }}
                  <span
                    v-if="menu.description"
                    class="text-sm text-gray-mud"
                  ><br />{{ menu.description }}</span>
                </a>
              </li>
            </template>
          </ul>
          <div class="gap-4 flex flex-col">
            <!--            <p class="text-[15px] font-bold">
              {{ $t('Контакти') }}
            </p>
            <AppHeaderContactsMenuBlock />-->
            <NuxtLink :to="localePath('/contacts')" class="link-bold-underline">
              {{ $t('Всі контакти') }}
            </NuxtLink>
          </div>
        </div>
      </PopoverPanel>
    </div>
    <!-- </app-transitions-dropdown> -->

    <AppBackdrop :show="open" />
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

// const { t } = useI18n()
const supportMenu = ref([
  // {
  //   name: 'Відстеження замовлення',
  //   href: '/tracking'
  // },
  // {
  //   name: t('Дисконтна програма'),
  //   href: '/'
  // },
  {
    name: 'Доставка і оплата',
    href: '/dostavka-i-oplata'
  },
  {
    name: 'Гарантія і повернення',
    href: '/obmin-ta-povernennya'
  },
  {
    name: 'terms_weapon_purchase',
    href: '/uslovyia-pryobrytenyia-oruzhyia'
  },
  {
    name: 'Питання та відповіді',
    href: '/faq'
  },
  {
    name: 'Дисконтна програма',
    href: '/dk'
  },
  {
    name: 'Подарункові сертифікати',
    href: '/gift-certificate'
  }
  // {
  //   name: t('Звʼязатись з нами'),
  //   href: '/'
  // }
])

const serviceIbisMenu = ref([
  {
    name: 'Сервіс-центри ІБІС',
    description: '',
    href: '/servisnyy-centr-ibis'
  },
  {
    name: 'menu_generator_service',
    href: '/servis-generatoriv'
  },
  {
    name: 'menu_reloading_program',
    href: '/programa-relodingu'
  },
  {
    name: 'fishing_workshops',
    href: '/servisnyy-centr-favorite-select-brain'
  }
])

const dealerIbisMenu = ref([
  {
    name: 'ribalka_magazini_i_dileri',
    href: '/ribalka-magazini-i-dileri'
  },
  {
    name: 'mislivstvo_dileri_v_regionakh',
    href: '/mislivstvo-dileri-v-regionakh'
  }
])

const aboutIbisMenu = ref([
  {
    name: 'Про компанію',
    description: '',
    href: '/about'
  },
  {
    name: 'Магазини',
    description: '',
    href: '/contacts#shop-addresses'
  },
  {
    name: 'Тир і стрілецький клуб',
    description: '',
    external: true,
    href: 'https://ibis-club.com.ua/'
  },
  // {
  //   name: t('База знань від ІБІС'),
  //   description: t('Статті і Відео'),
  //   href: '/'
  // },

  // {
  //   name: t('Турніри'),
  //   description: t('Статті і Відео'),
  //   href: '/'
  // },
  // {
  //   name: t('Оптова торгівля'),
  //   description: t('Співпраця'),
  //   href: '/'
  // },
  {
    name: 'Кулінарний дім',
    description: '',
    external: true,
    href: 'https://kuldom.ua/'
  }
])
const key = ref(null)
const open = ref(false)
// const showMenu = () => {
//   open.value = true
// }
const hideMenu = () => {
  open.value = false
}
const toggleMenu = () => {
  open.value = !open.value
}
watch(
  () => useRoute().path,
  () => {
    hideMenu()
    key.value = Date.now()
  }
)

let openTimeout = null

const handleMouseOver = () => {
  clearTimeout(openTimeout)
  openTimeout = setTimeout(() => {
    open.value = true
  }, 400)
}
const handleMouseLeave = () => {
  clearTimeout(openTimeout)
  open.value = false
}
</script>

<style>
.no-focus button:focus-visible {
  outline: none !important;
}
.no-focus:focus {
  outline: none !important;
}
</style>
