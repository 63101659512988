<template>
  <ListHorizontal
    v-if="markdownData?.length"
    :slides="markdownData"
    :slides-per-view="1"
    header-class="hidden"
    navigation-class="mt-5"
    hide-scrollbar
  >
    <template #default="{ slide: markdown }">
      <ProductSectionsCommonMarkdownItem
        :markdown="markdown"
        :type="itemType"
        :class="markdownData?.length > 1 && 'px-5'"
      />
    </template>
  </ListHorizontal>
</template>

<script setup>
const currentVariant = inject('currentVariant')

const itemType = ref('cheaper')

const markdownData = computed(() => {
  if (currentVariant.value?.originalProductVariant?.slug) {
    itemType.value = 'original'
    return [currentVariant.value?.originalProductVariant]
  } else if (currentVariant.value?.markdownProductVariants?.length) {
    itemType.value = 'cheaper'
    return currentVariant.value?.markdownProductVariants
  }
  return []
})
</script>
