<template>
  <div
    v-if="url"
    :style="{ backgroundImage: `url(${videoThumbnail})` }"
    class="w-full h-full bg-cover bg-center"
  >
    <IconsPlay
      class="w-30 h-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    />
  </div>
</template>

<script setup>
import last from 'lodash/last'

const props = defineProps({
  url: {
    type: String,
    default: ''
  }
})
const videoId = computed(() => last(props.url.split('/')))

const videoThumbnail = computed(() => {
  return videoId.value
    ? `https://img.youtube.com/vi/${videoId.value}/hqdefault.jpg`
    : ''
})
</script>
