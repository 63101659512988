<template>
  <div>
    <div
      v-if="product?.isSlider && product?.variants?.length > 1"
      class="mt-5 h-[147px]"
    >
      <ProductOptionsOptionProductSlider />
    </div>
    <div v-else>
      <template v-for="(option, idx) in allOptions" :key="option.id">
        <ProductOptionsOptionPickerProduct
          :value="selectedValues[option?.code]"
          :options="option?.values"
          :type="option?.type"
          :selected="selectedValues"
          :label="props.showLabel ? option?.name : ''"
          :parent-codes="idx !== 0 ? getParentCodes(idx) : []"
          class="mt-[18px]"
          @select="onSelect"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import fromPairs from 'lodash/fromPairs'
// import sortBy from 'lodash/sortBy'
// import last from 'lodash/last'

const product = inject('product')
const currentVariant = inject('currentVariant')
// const emit = defineEmits(['select-variant'])

const props = defineProps({
  showLabel: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: 'md'
  },
  productLink: {
    type: String,
    default: () => ''
  }
})

const allOptions = ref([])

const setAllOptions = () => {
  allOptions.value = product.value?.getSelectOptionsProduct()
  // console.log('allOptions.value', allOptions.value)
}

const selectedValues = computed(() =>
  fromPairs(
    currentVariant.value.optionValues.map(v => [v?.optionCode, v?.code])
  )
)

const getParentCodes = idx => {
  const codes = []
  for (let i = 0; i < idx; i++) {
    codes.push(selectedValues.value[allOptions.value[i]?.code])
  }

  return codes
}

const onSelect = data => {
  navigateTo(
    useLocalePath()(`/${useRoute().params?.category}/details/${data?.slug}/`)
  )
}

// const onSelect = ({ optionCode, code }) => {
//   let selectedVariant
//   // get list of variants for the selected option
//   const availableVariants = product.value.findVariantsForSelectedOption({
//     optionValueCode: code,
//     optionCode
//   })
//   // if variants weren't found, stop script
//   if (!availableVariants.length) {
//     // return selectedVariant
//     return false
//
//     // if only one variant was find, make it "current"
//   } else if (availableVariants.length === 1) {
//     selectedVariant = availableVariants[0]
//     // return navigateTo({
//     //   ...route + '/',
//     //   query: {
//     //     ...route.query,
//     //     v: availableVariants[0].code
//     //   }
//     // })
//
//     // if several variant was find for selected option, search the variant that match to maximal amount of the rest options
//   } else {
//     const sortedAvailableVariants = sortBy(
//       availableVariants,
//       v =>
//         v.optionValues.filter(
//           optionValue =>
//             selectedValues.value[optionValue.optionCode] === optionValue?.code
//         ).length
//     )
//
//     selectedVariant = last(sortedAvailableVariants)
//   }
//
//   emit('select-variant', selectedVariant)
// }
// onMounted(() => {
setAllOptions()
// })
</script>
