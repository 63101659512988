export default defineNuxtPlugin(() => {
  // const gaClientIdCookie = useCookie(GA_CLIENT_ID_KEY)
  // const gaSessionIdCookie = useCookie(GA_SESSION_ID_KEY)

  const pushEvent = (params = {}) => {
    if (import.meta?.server || !window) {
      return true
    }
    if (!window?.dataLayer) {
      window.dataLayer = []
    }

    // params.client_id = gaClientIdCookie.value
    // params.session_id = gaSessionIdCookie.value

    if (useAuthStore().loggedIn) {
      params.user_id = useAuthStore().userId
    }
    console.log('pushEvent', params)
    window.dataLayer.push(params)
  }

  const pushEcommerceEvent = (event, ecommerce) => {
    pushEvent({ ecommerce: null })
    pushEvent({ event, ecommerce })
  }

  const customPageView = () => {
    try {
      return pushEvent({
        event: 'custom_page_view'
      })
    } catch (e) {
      console.error(e)
    }
  }

  const viewCart = () => {
    try {
      const { cart } = useCartStore()
      return pushEcommerceEvent('view_cart', {
        currency: cart?.currencyCode,
        value: cart?.total ? cart?.total / 100 : 0,
        items: (cart?.items || []).map(prepareCartItem)
      })
    } catch (e) {
      console.error(e)
    }
  }

  const viewItem = (product, variant) => {
    try {
      const item = prepareProductVariant(product, variant)
      pushEcommerceEvent('view_item', {
        items: [item]
      })
    } catch (e) {
      console.error(e)
    }
  }

  const addToCart = cartItem => {
    try {
      const item = prepareCartItem(cartItem)
      return pushEcommerceEvent('add_to_cart', {
        currency: 'UAH',
        value: cartItem?.total / 100,
        items: [item]
      })
    } catch (e) {
      console.error(e)
    }
  }

  const removeFromCart = item => {
    try {
      return pushEcommerceEvent('remove_from_cart', {
        currency: 'UAH',
        value: item?.total / 100,
        items: [prepareCartItem(item)]
      })
    } catch (e) {
      console.error(e)
    }
  }

  const viewItemList = (category, products) => {
    try {
      const items =
        products?.map(product =>
          prepareProductVariant(product, product.getVariantByCode())
        ) || []
      return pushEcommerceEvent('view_item_list', {
        item_list_id: category?.code,
        item_list_name: category?.name,
        items
      })
    } catch (e) {
      console.error(e)
    }
  }

  const selectItem = (category, variant, product) => {
    try {
      const items = [prepareProductVariant(product, variant)]
      return pushEcommerceEvent('select_item', {
        item_list_id: category?.code,
        item_list_name: category?.name,
        items
      })
    } catch (e) {
      console.error(e)
    }
  }

  const beginCheckout = cart => {
    try {
      return pushEcommerceEvent('begin_checkout', {
        currency: cart?.currencyCode,
        value: cart?.total ? cart?.total / 100 : 0,
        items: (cart?.items || []).map(prepareCartItem)
      })
    } catch (e) {
      console.error(e)
    }
    // console.log('beginCheckout', cart)
  }

  const addShippingInfo = cart => {
    // add_shipping_info
    try {
      return pushEcommerceEvent('add_shipping_info', {
        currency: cart?.currencyCode,
        value: cart?.total ? cart?.total / 100 : 0,
        items: (cart?.items || []).map(prepareCartItem),
        shipping_tier: cart.shipments?.[0]?.method?.name || ''
      })
    } catch (e) {
      console.error(e)
    }
  }

  const addPaymentInfo = (cart, paymentType = '') => {
    // add_payment_info
    try {
      return pushEcommerceEvent('add_payment_info', {
        currency: cart?.currencyCode,
        payment_type: paymentType,
        value: cart?.total ? cart?.total / 100 : 0,
        items: (cart?.items || []).map(prepareCartItem)
      })
    } catch (e) {
      console.error(e)
    }
  }

  const searchPopUpShow = (query = '', total = 0) => {
    // search-pop-up-show'
    try {
      return pushEvent({
        event: 'search-pop-up-show',
        results: total,
        query
      })
    } catch (e) {
      console.error(e)
    }
  }

  const searchPageShow = (query = '', total = 0) => {
    // search-pop-up-show'
    try {
      return pushEvent({
        event: 'search-page-show',
        results: total,
        query
      })
    } catch (e) {
      console.error(e)
    }
  }

  const bannerClick = pageUrl => {
    // banner_click
    try {
      return pushEvent({
        event: 'banner_click',
        eventCategory: pageUrl
      })
    } catch (e) {
      console.error(e)
    }
  }

  const prepareProductVariant = (product, variant) => {
    const categories = useMenuStore().getCategoriesStack(
      product?.mainTaxon?.slug
    )
    // console.log('categories', categories)
    return {
      item_name: variant?.name,
      item_id: variant?.code,
      item_variant: '',
      price: variant?.price / 100,
      item_brand: product?.brand?.name || '',
      item_category: categories?.[0]?.name || product?.topTaxon?.name || '', // Передаєм категорію товар
      item_category2: categories?.[1]?.name || '', // Передаєм підкатегорію
      item_category3: categories?.[2]?.name || product?.mainTaxon?.name || '', // Передаєм підкатегорію
      item_category4: '', // Передаєм модель
      quantity: 1
    }
  }

  const prepareCartItem = cartItem => {
    const categories = useMenuStore().getCategoriesStack(
      cartItem?.variant?.product?.mainTaxon?.slug
    )
    return {
      item_name: cartItem?.variantName,
      item_id: cartItem?.variantCode,
      item_variant: '',
      price: cartItem?.unitPrice / 100,
      item_brand: cartItem?.brandName || '',
      item_category:
        categories?.[0]?.name ||
        cartItem?.variant?.product?.topTaxon?.name ||
        '', // Передаєм категорію товар
      item_category2: categories?.[1]?.name || '', // Передаєм підкатегорію
      item_category3:
        categories?.[2]?.name ||
        cartItem?.variant?.product?.mainTaxon?.name ||
        '', // Передаєм підкатегорію
      item_category4: '', // Передаєм модель
      quantity: cartItem?.quantity
    }
  }

  return {
    provide: {
      gtagEvent: {
        addPaymentInfo,
        addShippingInfo,
        addToCart,
        beginCheckout,
        removeFromCart,
        selectItem,
        customPageView,
        viewCart,
        viewItem,
        viewItemList,
        searchPopUpShow,
        searchPageShow,
        bannerClick
      }
    }
  }
})
