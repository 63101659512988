<template>
  <div class="bg-white lg:bg-gray h-full lg:pb-[100px]">
    <div class="container bg-gray pb-0">
      <AppBreadcrumbs
        :breadcrumbs="breadcrumbs"
        back-button
        class="pb-4 !mb-0"
      />
    </div>
    <div class="container space-y-2.5 pt-0">
      <div :key="currentVariant?.code" class="space-y-4 lg:space-y-0">
        <ProductSectionsCommon />
        <!--        <ProductSectionsCheaper />-->
        <LazyProductSectionsDetails />
      </div>

      <!--      <ListAssociatedProducts-->
      <!--        v-if="currentVariant?.inStock"-->
      <!--        type="similar"-->
      <!--        :product="product"-->
      <!--        class="bg-white lg:bg-transparent lg:pt-20"-->
      <!--      />-->
      <client-only>
        <LazyListWatchedProducts class="pt-20" />
      </client-only>
    </div>
  </div>
</template>

<script setup>
import ProductEntity from '~~/entities/Product.entity'
import { useCartStore } from '~~/stores/cart'
import { useWatchedProductsStore } from '~/stores/watchedProducts'
import TaxonEntity from '~/entities/Taxon.entity'
import { useMenuStore } from '~/stores/menu'
definePageMeta({
  middleware: ['category-exists']
})

const { $gtagEvent, $eSputnikEvent } = useNuxtApp()

const slug = computed(() => useRoute().params.slug)
const product = ref({})

const currentVariant = ref({})

provide('product', product)
provide('currentVariant', currentVariant)
provide('addToCart', async variant => {
  await useCartStore().addVariantToCart(
    variant?.code || currentVariant.value?.code
  )
  return useCartStore().openCart()
})
provide('getPrice', number => {
  const price = new Intl.NumberFormat('uk-UA', {
    style: 'currency',
    currency: 'UAH',
    minimumFractionDigits: 0,
    maximalFractionDigits: 2
  })
  if (isNaN(number)) {
    return price.format(0)
  }
  if (number === 0) {
    return ''
  }
  return price.format(number)
})

watch(currentVariant, () => {
  currentVariant.value &&
    useWatchedProductsStore().updateProducts(currentVariant.value?.code)
})

const { data, error } = await useAsyncData(
  'product-details-' + slug.value,
  () => useApi().products.getOneByVariantSlug(slug.value)
)
if (error?.value || !data.value) {
  if (error?.value?.status !== 401) {
    console.error(error?.value)
    await useRedirect(useRoute().fullPath, 'Page')
  } else {
    await useNeedLogin()
  }
}
product.value = data.value && new ProductEntity(data.value)

currentVariant.value = product?.value?.getVariant(slug.value)

const { data: seoData } = await useAsyncData(
  'product-page-seo-' + slug.value,
  () => useApi().seo.getByReferer()
)

provide('seoData', seoData)

useHead({
  title: seoData.value?.title || t('default_seo_title'),
  link: [
    {
      rel: 'canonical',
      href: useCanonical(seoData.value?.canonical) || '/'
    }
  ],
  meta: [
    {
      name: 'description',
      content: seoData.value?.description || t('default_seo_description')
    },
    {
      name: 'robots',
      content: seoData.value?.robots || 'index, follow'
    },
    {
      property: 'og:title',
      content: seoData.value?.title || t('default_seo_title')
    },
    {
      property: 'og:description',
      content: seoData.value?.description || t('default_seo_title')
    },
    {
      property: 'og:image',
      content: computed(
        () => currentVariant.value?.images[0]?.path || NO_IMAGE_PATH
      )
    },
    {
      property: 'og:type',
      content: 'article'
    },
    {
      property: 'og:url',
      content: useCanonical(seoData.value?.canonical) || '/'
    }
  ],
  script: [
    {
      key: 'lookSizeScript',
      innerHTML: `
              (function(w,d,s){
                w['LS']=w['LS']||{};
                LS.api_key='89763092fa396a929eb43895fcc';
                var f=d.getElementsByTagName(s)[0], j=d.createElement(s);
                j.src='https://www.looksize.com/get_ls_js.php?k='+LS.api_key;
                j.async=true;
                f.parentNode.insertBefore(j,f);
              })(window,document,'script');
            `,
      type: 'text/javascript',
      tagPosition: 'bodyClose',
      async: true
    }
  ]
})

const breadcrumbsService = useBreadcrumbsService()

const category = computed(
  () =>
    new TaxonEntity(
      useMenuStore().findCategoryBySlug(product.value?.mainTaxon?.slug)
    )
)

const breadcrumbs = computed(() => [
  ...breadcrumbsService.forProduct(category.value, product.value?.topTaxon),
  { name: currentVariant.value.name, to: '' }
])
const categoriesStack = computed(
  () => useMenuStore().getCategoriesStack(category.value.slug) || []
)

const reviewsSchema = computed(() => {
  const schema = {}

  if (product.value?.totalReviews && product.value?.ratingCount) {
    schema.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: product.value?.averageRating,
      reviewCount: product.value?.totalReviews,
      ratingCount: product.value?.ratingCount,
      worstRating: product.value?.worstRating,
      bestRating: product.value?.bestRating
    }
  }
  schema.review = (product.value?.recentReviews || [])
    .map(review => ({
      '@type': 'Review',
      author: review?.author,
      name: currentVariant.value?.name,
      description: review?.description,
      datePublished: review?.createdAt,
      reviewRating: {
        '@type': 'AggregateRating',
        ratingValue: review?.reviewRating?.ratingValue,
        ratingCount: product.value?.ratingCount
      }
    }))
    .filter(item => item.reviewRating.ratingValue)

  return schema
})

useSchemaOrg([
  defineBreadcrumb({
    itemListElement: [
      { name: useI18n().t('Ібіс'), item: useLocalePath()('/') },
      ...categoriesStack.value.map((cat, idx) => ({
        name: cat.name,
        item: useLocalePath()(
          `${idx ? '/' + categoriesStack.value?.[0]?.slug : ''}/${cat.slug}/`
        )
      })),
      {
        name: currentVariant.value?.name,
        item: useRoute().fullPath
      }
    ]
  }),
  {
    '@type': 'Product',
    sku: currentVariant.value?.code,
    name: currentVariant.value?.name,
    description: product.value?.description,
    brand: product.value?.brand?.name,
    image: currentVariant.value?.images?.map(i =>
      i?.path ? useAssetUrl(i?.path) : ''
    ),
    offers: {
      '@type': 'Offer',
      priceCurrency: 'UAH',
      price: currentVariant.value?.price / 100,
      url: useRoute().fullPath,
      availability: currentVariant.value?.inStock
        ? 'http://schema.org/InStock'
        : '',
      itemCondition: 'http://schema.org/NewCondition'
    },
    ...reviewsSchema.value
  }
])

onMounted(() => {
  $gtagEvent.viewItem(
    product.value,
    currentVariant.value,
    breadcrumbs.value.slice(1)
  )
  $eSputnikEvent.productPage(currentVariant.value)
})
</script>
