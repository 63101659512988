export default function () {
  const { siteEnv, metaPixelCodeId } = useRuntimeConfig().public

  useHead({
    script: [
      { src: 'https://connect.facebook.net/uk_UA/sdk.js', async: true },
      {
        key: 'esputnikTrackerScript',
        innerHTML: `
          !function (t, e, c, n) {
        var s = e.createElement(c);
        s.async = 1, s.src = 'https://statics.esputnik.com/scripts/' + n + '.js';
        var r = e.scripts[0];
        r.parentNode.insertBefore(s, r);
        var f = function () {
            f.c(arguments);
        };
        f.q = [];
        f.c = function () {
            f.q.push(arguments);
        };
        t['eS'] = t['eS'] || f;
        }(window, document, 'script', '${
          siteEnv === 'production' ? '4FD0C8C92CE24C27BCC52C239F112BF3' : ''
        }');
        eS('init', {
           TRACKING: false,
           RECOMS: true
        });
        `,
        type: 'text/javascript',
        tagPosition: 'bodyClose',
        async: true
      },
      {
        key: 'metaPixelCodeScript',
        innerHTML: metaPixelCodeId
          ? `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${metaPixelCodeId}');
          fbq('track', 'PageView');
        `
          : '',
        tagPosition: 'head',
        async: true
      },
      {
        key: 'esputnikPushCodeScript',
        innerHTML: `(function(i,s,o,g,r,a,m){
        i["esSdk"] = r;
        i[r] = i[r] || function() {
        (i[r].q = i[r].q || []).push(arguments)
        }, a=s.createElement(o), m=s.getElementsByTagName(o)[0]; a.async=1; a.src=g;
        m.parentNode.insertBefore(a,m)}
        ) (window, document, "script", "https://esputnik.com/scripts/v1/public/scripts?apiKey=eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0NTI0ZWZhYTJkYzI2MGRmYTM4YTE1NDBlMWEyYWY0ZWQ0YmYzOTFmMmY0YmNjYjMwYmI5NTA2YWRjOTc0NTA2NjhhMmZjMzUwMmEyYWM2MjZmNDU3YWY5YzcyMWM3MGQwOGU4Yzg1NzQxM2E3MWJhYjMzOTVjODMyNTFlZWEwMmQ4OWViZDNlMzc3YzYxNTZhYzdkYTI2MmViZGMxNjAyMGQ4YWUzOTU5OTFkNjE1ZmYzMGJhZjk1OTBhOWNmIn0.QbQLlIxQPdGmRETE_Un8Sn416V1AZ2YjzJnFVQEOdE-9EOU0YO9uZjr0-q9_KB5siAQeHSXYxcY9eQQJM5PzrQ&domain=91101913-384D-4117-AAC5-997744DB8108", "es");
        es("pushOn", {'service-worker': {'relUrl': '/sw.js'} });
        `,
        type: 'text/javascript',
        tagPosition: 'bodyClose',
        async: true
      }
    ],
    noscript: [
      {
        key: 'metaPixelCodeNoScript',
        innerHTML: metaPixelCodeId
          ? `<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=${metaPixelCodeId}&ev=PageView&noscript=1"
          />`
          : '',
        tagPosition: 'bodyOpen'
      }
    ]
  })

  const { multisearchTracker } = useRuntimeConfig().public

  if (multisearchTracker && import.meta.client) {
    if (!window?.dataLayer) {
      window.dataLayer = []
    }
    useHead({
      script: [
        {
          key: 'multisearchTrackerScript',
          innerHTML: `
                  (function(d) {
                      var s = d.createElement('script');
                      s.defer = true;
                      s.src = 'https://tracker.multisearch.io/3894dae9a35f';
                      if (d.head) d.head.appendChild(s);
                  })(document);
                `,
          tagPosition: 'head',
          async: true
        }
      ]
    })
  }
}
