<template>
  <div class="h-full overflow-y-auto bg-white py-2.5 flex flex-col">
    <div class="px-2.5">
      <DialogTitle class="flex justify-between items-center">
        <IconsLogoExtended />
        <AppFormSwitcher
          v-model="lang"
          :options="i18n.locales.value"
          :get-label="o => o.name"
          :get-value="o => o.code"
        />
      </DialogTitle>
    </div>
    <nav aria-label="Sidebar" class="relative text-sm mt-6 flex-1 px-2.5">
      <ul class="uppercase">
        <li>
          <button
            class="flex items-center h-10 gap-3.5 px-[11px] bg-gray rounded-md border-none hover:text-primary w-full uppercase"
            @click="emits('showCatalog')"
          >
            <IconsCategory class="h-5 w-5 text-green-ibis" />
            {{ t('Каталог товарів') }}
            <ChevronRightIcon class="h-3.5 ml-auto" />
          </button>
        </li>
        <template v-for="item in navigation" :key="item.name + item.to">
          <li v-if="!item.children" class="border-b">
            <NuxtLink
              v-if="item.to"
              :to="localePath(item.to)"
              class="flex items-center h-10 gap-3.5 px-[11px] hover:text-primary cursor-pointe uppercase"
              @click="item.click"
            >
              <component
                :is="item.icon"
                v-if="item.icon"
                class="h-5 w-5 text-gray-mud"
              />
              {{ item.name }}
            </NuxtLink>
            <button
              v-else
              class="flex items-center h-10 gap-3.5 px-[11px] hover:text-primary w-full uppercase"
              @click="item.click"
            >
              <component
                :is="item.icon"
                v-if="item.icon"
                class="h-5 w-5 text-gray-mud"
              />
              {{ item.name }}
            </button>
          </li>

          <Disclosure
            v-else
            v-slot="{ open: disclosureOpen }"
            as="li"
            class="border-b"
            :default-open="item.defaultOpen"
          >
            <DisclosureButton
              :class="[
                'flex justify-between items-center w-full h-10 gap-3.5 px-[11px] hover:text-primary uppercase',
                disclosureOpen ? 'text-primary' : ''
              ]"
              :aria-label="item.name"
            >
              {{ item.name }}
              <IconsDropdownChevron
                :active="disclosureOpen"
                active-class="text-primary"
              />
            </DisclosureButton>
            <DisclosurePanel>
              <NuxtLink
                v-for="child in item.children"
                :key="child.name"
                :to="localePath(child.to)"
                class="flex items-center h-10 px-[31px] hover:text-primary normal-case"
                :class="{
                  'h-20': child.name === t('ribalka_magazini_i_dileri')
                }"
              >
                {{ child.name }}
              </NuxtLink>
            </DisclosurePanel>
          </Disclosure>
        </template>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import {
  DialogTitle,
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/24/outline'
import { useAuthStore } from '~~/stores/auth'
import { useCartStore } from '~~/stores/cart'

const { t } = useI18n()
const emits = defineEmits(['showCatalog', 'showCompare'])

const i18n = useI18n()
const lang = useLang()

const authStore = useAuthStore()
const cartStore = useCartStore()
const navigation = computed(() =>
  [
    {
      name: t('Увійти'),
      icon: resolveComponent('IconsUser'),
      children: false,
      hide: authStore.loggedIn || authStore.loggedInStore,
      click: e => {
        e.preventDefault()
        authStore.showAuthDialog()
      }
    },

    {
      name: t('Мій кабінет'),
      icon: resolveComponent('IconsUser'),
      children: false,
      hide: !authStore.loggedIn,
      to: '/cabinet'
    },

    {
      name: t('Мої замовлення'),
      icon: resolveComponent('IconsList'),
      children: false,
      hide: !authStore.loggedIn,
      to: '/cabinet/orders'
    },
    {
      name: t('Кошик'),
      icon: resolveComponent('IconsCart'),
      children: false,
      click: e => {
        e.preventDefault()
        cartStore.openCart()
      }
    },
    {
      name: t('Порівняння'),
      icon: resolveComponent('IconsComparison'),
      children: false,
      click: e => {
        e.preventDefault()
        emits('showCompare')
      }
    },
    {
      name: t('Обране'),
      icon: resolveComponent('IconsHeart'),
      children: false,
      to: '/cabinet/favorites'
    },
    /*    {
      name: t('Бренди'),
      icon: resolveComponent('IconsList'),
      children: false,
      to: '/brand/'
    }, */
    {
      name: t('menu_posts'),
      icon: resolveComponent('IconsGift'),
      children: false,
      to: '/post/'
    },
    {
      name: t('Магазини'),
      icon: resolveComponent('IconsMap'),
      children: false,
      to: '/contacts/#shop-addresses'
    },
    {
      name: t('Підтримка'),
      defaultOpen: true,
      children: [
        // {
        //   name: t('Відстежити замовлення'),
        //   to: '/tracking'
        // },
        // {
        //   name: t('Дисконтна програма'),
        //   to: '/'
        // },
        {
          name: t('Доставка і оплата'),
          to: '/dostavka-i-oplata'
        },
        {
          name: t('Гарантія та повернення'),
          to: '/garantijnye-objazatelstva'
        },
        {
          name: t('terms_weapon_purchase'),
          to: '/uslovyia-pryobrytenyia-oruzhyia'
        },
        {
          name: t('Сервіс-центри ІБІС'),
          to: '/servisnyy-centr-ibis'
        },
        {
          name: t('Питання та відповіді'),
          to: '/faq'
        },
        {
          name: t('Тир і стрілецький клуб'),
          to: 'https://ibis-club.com.ua/',
          external: true
        },
        {
          name: t('Дисконтна програма'),
          to: '/dk'
        },
        {
          name: t('Подарункові сертифікати'),
          to: '/gift-certificate'
        }
        // {
        //   name: t('Зв’язатись з нами'),
        //   to: '/contacts'
        // }
      ]
    },
    {
      name: t('Cервіси ІБІС'),
      defaultOpen: false,
      children: [
        {
          name: t('Сервіс-центри ІБІС'),
          to: '/servisnyy-centr-ibis'
        },
        {
          name: t('menu_generator_service'),
          to: '/servis-generatoriv'
        },
        {
          name: t('menu_reloading_program'),
          to: '/programa-relodingu'
        },
        {
          name: t('fishing_workshops'),
          to: '/servisnyy-centr-favorite-select-brain'
        }
      ]
    },
    {
      name: t('Дилери в регіонах'),
      defaultOpen: false,
      children: [
        {
          name: t('ribalka_magazini_i_dileri'),
          to: '/ribalka-magazini-i-dileri'
        },
        {
          name: t('mislivstvo_dileri_v_regionakh'),
          to: '/mislivstvo-dileri-v-regionakh'
        }
      ]
    },
    {
      name: t('Про IБІС'),
      to: '/about'
    },
    {
      name: t('Контакти'),
      to: '/contacts'
    }
  ].filter(i => !i.hide)
)
</script>
