<template>
  <nuxt-link
    :to="localePath('/post/' + postData?.slug)"
    class="xs:item-container flex flex-col border-transparent xs:border-gray border-solid relative w-[auto] h-auto xs:h-[455px] p-0 xs:p-5 hover:text-orange hover:cursor-pointer overflow-hidden"
  >
    <div
      class="relative w-full h-full object-cover max-h-[250px] min-h-[250px]"
    >
      <nuxt-img
        :src="postData?.image || NO_IMAGE_PATH"
        class="w-full rounded h-[205px]"
        width="420"
        height="180"
        loading="lazy"
        format="webp"
      />
      <!-- <div class="swiper-lazy-preloader"></div> -->
    </div>

    <div class="flex flex-col flex-1 mb-auto">
      <div class="font-semibold text-[15px] text-green-evergreen line-clamp-2">
        {{ postData?.name }}
      </div>

      <div
        v-if="postData?.shortContent"
        class="text-sm mt-3 text-green-swamp line-clamp-4 h-[80px] max-h-28 md:max-h-none news-swiper-content"
        v-html="postData?.shortContent"
      ></div>
    </div>

    <div class="flex justify-between text-xs text-gray-mud mt-3">
      <div class="flex">
        <IconsCalendar class="h-4 w-4 mr-2" />
        <span v-if="postData?.saleDateFrom || postData?.saleDateTo">
          <span
            v-if="postData?.saleDateFrom"
            class="mr-1"
          >{{ t('from') }}
            {{
              postData?.getPromotionDate &&
                postData?.getPromotionDate(postData?.saleDateFrom)
            }}</span>
          <span v-if="props.data?.saleDateTo">{{ t('to') }}
            {{
              postData?.getPromotionDate &&
                postData?.getPromotionDate(postData?.saleDateTo)
            }}</span>
        </span>
        <span v-else>{{ postData?.getDate && postData?.getDate() }}</span>
      </div>

      <!--        <div class="flex">-->
      <!--          <IconsClock class="h-4 w-4 mr-2" />-->
      <!--          {{ props.data.readTime }} хвилин-->
      <!--        </div>-->
      <div
        v-if="label"
        :class="[
          'flex p-0.5 text-white text-xs font-bold w-fit rounded right-[20px] pr-3 pl-3',
          {
            'bg-red-600': postCode === 'posts_video_19603',
            'bg-green-500': postCode === 'posts_novosti_19606',
            'bg-primary': postCode === 'posts_akcii_19601',
            'bg-gray-400': postCode === 'posts_stati_19614'
          }
        ]"
      >
        {{ label }}
      </div>
    </div>
  </nuxt-link>
</template>

<script setup>
import get from 'lodash/get'
import PageEntity from '~/entities/Page.entity'

const { t } = useI18n()

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

const postData = computed(() => new PageEntity(props.data))
const label = computed(() => get(props.data, ['taxons', '0', 'name'], 'Новини'))
const postCode = computed(() =>
  get(props.data, ['taxons', '0', 'code'], 'code')
)
</script>

<style scoped>
.item-container:hover {
  box-shadow: 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02), 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02);
  background: #ffffff;
}
</style>

<style lang="postcss">
/* fixes for mobile safari */
.news-swiper-content img {
  @apply hidden;
}
.news-swiper-content * {
  @apply inline;
}
</style>
