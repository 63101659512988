<template>
  <div
    :key="key"
    class="container h-[43px] hidden lg:flex items-center gap-[30px] text-green-evergreen text-xs"
    @mouseout="setIsShown(false)"
  >
    <AppHeaderMenu />
    <PopoverGroup
      v-if="categoriesList"
      class="md:flex items-center"
      @mouseover="setIsShown(true)"
    >
      <div
        v-for="(category, index) in categoriesList"
        :key="index + '-' + category.id"
      >
        <AppHeaderCategoryMenu
          :category-id="category.id"
          :show-all="!index"
          :banner="bannerImage"
          :is-shown="isShown"
        />
      </div>
    </PopoverGroup>
    <div class="ml-auto uppercase font-bold hover:text-primary">
      <NuxtLink :to="localePath('/contacts#shop-addresses')">
        {{ t('Магазини') }}
      </NuxtLink>
    </div>
    <!--    <div class="uppercase font-bold hover:text-primary">
      <NuxtLink :to="localePath('/brand')">
        {{ t('Бренди') }}
      </NuxtLink>
    </div>-->
    <div class="uppercase font-bold text-primary">
      <NuxtLink :to="localePath('/post')">
        {{ t('Акції та Новинки') }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
import { PopoverGroup } from '@headlessui/vue'
import { useMenuStore } from '~/stores/menu'
import Collection from '~/entities/Collection'

const { t } = useI18n()
const menuStore = useMenuStore()

const categoriesList = computed(() => menuStore.menu)

const storeTypes = useStoreType()

const { data: bannerImage } = useLazyAsyncData(
  'banner_menu',
  () => $fetch('/api/menu-banners'),
  // .then(res => {
  //   console.log('/api/menu-banners', res)
  //   return res && new Collection(res)?.[0]
  // })
  {
    transform: res => res && new Collection(res)?.data?.[0]
  }
)

const key = ref(null)
const isShown = ref(false)

const setIsShown = val => {
  setTimeout(() => {
    isShown.value = val
  }, 400)
}

onMounted(() => {
  // key.value = 'nav-desktop'
  key.value = Date.now().toString()
})
</script>
