<template>
  <ListHorizontal
    v-if="brands?.length"
    :title="t('brands_block_header')"
    :sub-title="t('brands_block_description')"
    :list-link="listLink"
    :link-class="props.linkClass"
    :slides="brands"
    :slides-per-view="2"
    navigation-class="brands-navigation-button"
    :breakpoints="{
      600: {
        slidesPerView: 3
      },
      900: {
        slidesPerView: 4
      },
      1050: {
        slidesPerView: 5
      },
      1200: {
        slidesPerView: 6
      },
      1350: {
        slidesPerView: 7
      },
      1500: {
        slidesPerView: 8
      }
    }"
  >
    <template #default="{ slide: brandsPair }">
      <div class="mb-5">
        <ListBrandsItem
          v-for="brand in brandsPair"
          :key="brand.id"
          :brand="brand"
        />
      </div>
    </template>
  </ListHorizontal>
  <div v-else class="h-[340px] w-full pulse"></div>
</template>
<script setup>
import chunk from 'lodash/chunk'
import BrandEntity from '~~/entities/Brand.entity'

const { t } = useI18n()

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default:
      'ІБІС є екслюзивним та офіційним представником більш ніж 300 брендів і є власником 15 торгових марок'
  },
  linkClass: {
    type: String,
    default: ''
  }
})
const listLink = {
  label: t('Всі бренди'),
  to: '/brand/'
}

const { data } = useLazyAsyncData(
  'brands-home-page',
  () =>
    useApi().brands.getAll({
      itemsPerPage: 30,
      page: 1,
      filter: PRODUCT_IMAGE_FILTER.BANNER_MENU
    }),
  {
    transform: res => res['hydra:member']
  }
)

const brands = computed(
  () =>
    data?.value &&
    chunk(
      data.value.map(brand => new BrandEntity(brand)),
      2
    )
)
</script>

<style lang="postcss" scoped>
.item-container:hover {
  box-shadow: 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02), 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02);
  background: white;
}
</style>
