<template>
  <div class="p-[10px] bg-light-orange rounded-md">
    <div class="font-bold text-black/75">
      {{ $t(title) }}
      <span v-if="discount" class="text-primary">- {{ discount }}%</span>
    </div>
    <div class="flex flex-col md:flex-row justify-between w-auto mt-[10px]">
      <div class="flex items-center flex-grow min-w-0">
        <nuxt-link
          :to="getProductLink()"
          class="flex-shrink-0 h-[44px] w-[44px] p-[2px] border-[2px] border-primary flex items-center justify-center rounded-md bg-white"
        >
          <img
            height="37"
            width="37"
            class="max-h-[37px] max-w-[37px] object-contain"
            :src="image"
            :alt="props.markdown?.name"
          />
        </nuxt-link>

        <nuxt-link
          :to="getProductLink()"
          class="ml-[10px] flex flex-col justify-center min-w-0 text-sm"
        >
          <div
            v-if="markdown?.inStock"
            class="flex items-center text-green-ibis gap-2"
          >
            <IconsProductAvailabilityCheck class="h-2.25 w-3.5" />
            <span>{{ $t('Є в наявності') }}</span>
          </div>
          <div v-else class="flex items-center text-gray-mud gap-1">
            <IconsProductNotAvailable class="w-3.5" />
            <span class="-mb-0.5">{{ $t('Немає в наявності') }}</span>
          </div>
          <div class="truncate mr-3" :title="props.markdown?.name">
            {{ props.markdown?.name }}
          </div>
        </nuxt-link>
      </div>

      <div
        class="flex-shrink-0 flex flex-col mr-3 mt-3 md:mt-0"
        :class="!discount && 'flex justify-start'"
      >
        <div
          v-if="
            discount && props.markdown?.originalPrice > props.markdown?.price
          "
          class="space-x-1 text-[12px]"
        >
          <span
            class="line-through"
            :class="
              props.markdown?.inStock
                ? 'decoration-primary'
                : 'decoration-gray-mud'
            "
          >
            {{ getPrice(props.markdown?.originalPrice / 100) }}
          </span>
          <span class="px-1 bg-discount"> -{{ discount }}% </span>
        </div>
        <div
          class="font-bold text-md"
          :class="[
            props.type === 'cheaper' && 'text-primary',
            discount && 'leading-[36px]'
          ]"
        >
          {{ getPrice(props.markdown?.price / 100) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ProductVariantEntity from '~~/entities/ProductVariant.entity'

const props = defineProps({
  markdown: {
    type: Object,
    default: () => {}
  },
  type: {
    type: String,
    default: 'cheaper'
  }
})

const { locale } = useI18n()
const image = computed(() => props.markdown?.images[0]?.path || NO_IMAGE_PATH)
const title = computed(() =>
  props.type === 'cheaper'
    ? 'markdown_cheaper_title'
    : 'markdown_original_title'
)
const getPrice = inject('getPrice')
const discount = computed(() => useDiscountCalculator(props.markdown))

const getProductLink = () => {
  return ProductVariantEntity.buildProductLink(
    props.markdown,
    props.markdown,
    '',
    locale.value
  )
}
</script>

<style scoped>
.bg-light-orange {
  background-color: rgba(255, 97, 47, 0.1);
}
.bg-discount {
  background-color: rgba(255, 97, 47, 0.2);
}
</style>
