<template>
  <div>
    <iframe ref="childFrame" :src="iframeSrc" style="display: none"></iframe>
  </div>
</template>

<script setup>
// import { storeToRefs } from 'pinia'

const authStore = useAuthStore()
const storeType = useStoreType()
const host = storeType.isHunting
  ? storeType.fishingDomain
  : storeType.huntingDomain

// let worker
const iframeSrc = `${host}/sync-cookies-2/`
const childFrame = ref(null)

// const { token, refresh, customerId, rememberCustomer, tokenStore, refreshStore } =
//     storeToRefs(authStore)

const cartToken = computed(() => useCartStore().cartToken)
// const scCookie = useCookie(SC_TOKEN_COOKIE_KEY)
// const gaSessionIdCookie = useCookie(GA_SESSION_ID_KEY)
// const gaClientIdCookie = useCookie(GA_CLIENT_ID_KEY)

// const getPayload = async () => {
//   await useGaCookies()
//   return {
//     token: token.value || '',
//     refresh: refresh.value || '',
//     rememberCustomer: rememberCustomer.value || '',
//     customerId: customerId.value || '',
//     tokenStore: tokenStore.value || '',
//     refreshStore: refreshStore.value || '',
//     cartToken: cartToken.value || '',
//     sc: scCookie.value || '',
//     gaClientId: gaSessionIdCookie.value || '', // need implement
//     gaSessionId: gaClientIdCookie.value || '' // need implement
//   }
// }

// const sendWorkerData = async () => {
//   worker && worker.port.postMessage({ type: 'SetCookies', ...await getPayload() })
// }

// const sendPostData = async () => {
//   childFrame.value && childFrame.value.contentWindow.postMessage({
//     type: 'SetCookies',
//     ...await getPayload()
//   }, host)
// }
const sendPostData = () => {
  childFrame.value &&
    childFrame.value.contentWindow.postMessage(
      {
        cartToken: cartToken.value
      },
      host
    )
}
//
// watch(token, () => {
//   // sendWorkerData()
//   sendPostData()
// })
// watch(tokenStore, () => {
//   // sendWorkerData()
//   sendPostData()
// })
watch(cartToken, () => {
  // sendWorkerData()
  sendPostData()
})
// watch(scCookie, () => {
//   // sendWorkerData()
//   sendPostData()
// })

onMounted(() => {
  if (childFrame.value) {
    childFrame.value.onload = function () {
      console.log('send in child iframe')
      setTimeout(() => {
        sendPostData()
      }, 2000)
    }
  }
  // if (window.SharedWorker) {
  //   // Підключення до SharedWorker
  //   worker = new SharedWorker('/shared-worker.js')
  //   worker.port.start()
  //
  //   // Відправка повідомлення до SharedWorker
  //   sendWorkerData()
  //
  //   // Відправка повідомлення в iframe
  //   if (childFrame.value) {
  //     childFrame.value.onload = function() {
  //       console.log('send in child iframe')
  //       setTimeout(() => {
  //         sendPostData()
  //       }, 2000)
  //     }
  //   }
  // } else {
  //   console.error('SharedWorker is not supported in this browser')
  // }
})
</script>
