import unhead_KgADcZ0jPj from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/ibis_dev/ibis_frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/ibis_dev/ibis_frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_eskviOYyLt from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/builds/ibis_dev/ibis_frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import api_QQzOodE9HA from "/builds/ibis_dev/ibis_frontend/plugins/api.js";
import eSputnikEvent_oFgTZ7ygIN from "/builds/ibis_dev/ibis_frontend/plugins/eSputnikEvent.js";
import errorHandler_cfkZ75xwSQ from "/builds/ibis_dev/ibis_frontend/plugins/errorHandler.js";
import gtagEvent_1GdY02UjLa from "/builds/ibis_dev/ibis_frontend/plugins/gtagEvent.js";
import helpcrunch_mSoFKKVNHt from "/builds/ibis_dev/ibis_frontend/plugins/helpcrunch.js";
import veeValidator_h6eVcyWIYj from "/builds/ibis_dev/ibis_frontend/plugins/veeValidator.js";
import vue3_toastify_zPoFw264mR from "/builds/ibis_dev/ibis_frontend/plugins/vue3-toastify.js";
import vueGoogleMaps_8xmoAwKZvr from "/builds/ibis_dev/ibis_frontend/plugins/vueGoogleMaps.ts";
import vueLoadingOverlay_Vv76zDRBto from "/builds/ibis_dev/ibis_frontend/plugins/vueLoadingOverlay.js";
import vueYoutube_iONrDVUBji from "/builds/ibis_dev/ibis_frontend/plugins/vueYoutube.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  plugin_eskviOYyLt,
  i18n_yfWm7jX06p,
  api_QQzOodE9HA,
  eSputnikEvent_oFgTZ7ygIN,
  errorHandler_cfkZ75xwSQ,
  gtagEvent_1GdY02UjLa,
  helpcrunch_mSoFKKVNHt,
  veeValidator_h6eVcyWIYj,
  vue3_toastify_zPoFw264mR,
  vueGoogleMaps_8xmoAwKZvr,
  vueLoadingOverlay_Vv76zDRBto,
  vueYoutube_iONrDVUBji
]