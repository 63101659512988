import api from '../api.client'
const getPath = (...suffixPath) =>
  ['/api/v2/shop/orders', ...suffixPath].filter(v => v).join('/')
const defaultPagination = {
  page: 1,
  itemsPerPage: 30
}
export default {
  getAll: (payload, options = {}) =>
    api(options).get(getPath(), { ...defaultPagination, ...payload }),
  getOne: (token, options = {}) => api(options).get(getPath(token)),
  getCartItemsCount: (token, options = {}) =>
    api(options).get(getPath(token, 'cart')),
  setCustomerCart: (token, options = {}) =>
    api(options).patch(getPath(token, 'set-customer'), {}),
  create: (options = {}) => api(options).post(getPath(), {}),
  remove: (token, options = {}) => api(options).delete(getPath(token)),
  update: (token, payload, options = {}) =>
    api(options).put(getPath(token), payload),

  cancel: (token, options = {}) => api(options).patch(getPath(token, 'cancel')),

  // COMPLETE
  complete: (token, payload = { notes: '' }, options = {}) =>
    api(options).patch(getPath(token, 'complete'), payload),

  // CHECKOUT
  checkout: (token, payload, options = {}) =>
    api(options).patch(getPath(token, 'checkout'), payload),

  // FAST ORDER
  fastOrder: (token, payload, options = {}) =>
    api(options).patch(getPath(token, 'quick-checkout '), payload),

  // ITEMS
  addItem: (token, payload, quantity = 1, options = {}) => {
    return api(options).post(getPath(token, 'items'), {
      quantity,
      productVariant: undefined,
      ...payload
    })
  },
  addMultipleItems: (token, payload, options = {}) => {
    return api(options).post(getPath(token, 'bulk'), payload)
  },
  getItems: (token, payload, options = {}) =>
    api(options).get(getPath(token, 'items'), {
      ...defaultPagination,
      ...payload
    }),
  removeItem: (token, itemId, options = {}) =>
    api(options).delete(getPath(token, 'items', itemId)),
  removeMultipleItems: (token, items, options = {}) =>
    api(options).patch(getPath(token, 'items', 'delete-bulk'), items),
  changeQuantity: (token, itemId, quantity, options = {}) =>
    api(options).patch(getPath(token, 'items', itemId), { quantity }),
  changeMultipleQuantity: (token, items, options = {}) =>
    api(options).patch(getPath(token, 'items', 'bulk'), items),

  // PAYMENTS
  selectPaymentMethod: (token, paymentId, paymentMethod, options = {}) =>
    api(options).patch(getPath(token, 'payments', paymentId), {
      paymentMethod
    }),
  getPaymentMethodConfig: (token, paymentId, options = {}) =>
    api(options).get(getPath(token, 'payments', paymentId, 'configuration')),
  pay: (token, options = {}) => {
    api(options).get(getPath(token, 'pay'))
  },

  // SHIPMENTS
  selectShippingMethod: (token, shipmentId, shippingMethod, options = {}) =>
    api(options).patch(getPath(token, 'shipments', shipmentId), {
      shippingMethod
    }),

  checkCertificate: (certificateCode, options = {}) =>
    api(options).get(`/api/v2/shop/certificates/check/${certificateCode}`)
}
