import get from 'lodash/get'
import { useCartStore } from './cart'
import { usePreorderStore } from './preorder'
import WishlistEntity from '~~/entities/Wishlist.entity'
import {
  getSCCookieOptions,
  setScToken,
  getScToken
} from '~/utils/helpers/auth'
import { useAuthStore } from '~~/stores/auth'

const { show: showLoader, hide: hideLoader } = useLoader()

const transformUserData = data => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    fullName: data.fullName,
    email: data.email,
    birthday: data?.birthday?.split(' ')[0],
    patronymic: data.patronymic,
    phoneNumber: data?.user?.phoneNumber,
    wishlists: data?.user?.wishlists || [],
    carts: (data?.orders || []).filter(o => o.state === 'cart'),
    defaultAddress: data?.defaultAddress,
    defaultRecipient: data?.defaultRecipient,
    avatar: data.user?.avatar?.path,
    totalInWishlists: data?.totalInWishlists,
    socialProfiles: data?.user?.socialProfiles,
    totalOrders: data?.totalOrders || 0,
    subscriptions: data?.subscriptions || [],
    group: data?.group || null,
    discountCardCode: data?.discountCardCode
    // etc
  }
}

export const useUserStore = defineStore({
  id: 'user-store',
  state: () => {
    return {
      user: null,
      loading: false,
      scToken: getScToken()
    }
  },
  actions: {
    fetchUserDataIfNotExist(silent = false, to = null) {
      if (this.getUser) {
        return this.getUser
      }

      if (to && to?.path?.includes('cabinet')) {
        return false
      } else if (to && to?.path?.includes('checkout')) {
        return this.fetchUserData(silent)
      } else {
        return this.fetchShortInfo(silent)
      }
    },
    fetchUserData(silent = false) {
      const authStore = useAuthStore()
      const customerId = authStore.userId
      if (customerId && authStore.token) {
        return useApi().customers.getCustomer(customerId, {
          onSuccess: async data => {
            this.user = transformUserData(data)
            const order = get(data, ['orders'], []).find(
              o => o.state === 'cart'
            )
            const preorder = get(data, ['preorders'], []).find(
              o => o.state === 'cart'
            )
            const token = useCartStore().cartToken
            if (!order && token) {
              useApi().orders.setCustomerCart(token)
            }
            if (preorder) {
              await usePreorderStore().setCart(preorder)
            }
            if (data?.scCookie) {
              const cookieOptions = getSCCookieOptions(true)
              setScToken(data?.scCookie, cookieOptions)
            } else if (this.scToken) {
              // useApi().customers.setScCookie(this.scToken)
            }
            return order && useCartStore().setCart(order)
          },
          onError: () => {
            if (!silent) {
              // navigateTo('/404')
              throw createError({
                statusCode: 404,
                statusMessage: 'Page Not Found'
              })
            }
          }
        })
      }
    },

    fetchShortInfo(silent = false) {
      const authStore = useAuthStore()
      const customerId = authStore.userId
      if (customerId && authStore.token) {
        return useApi().customers.getShortInfo({
          onSuccess: data => {
            this.user = transformUserData(data)
            const token = useCartStore().cartToken
            if (!data.token && token) {
              useApi().orders.setCustomerCart(token)
            } else {
              useCartStore().updateCount(data.totalProducts)
            }
            if (data?.scCookie) {
              const cookieOptions = getSCCookieOptions(true)
              setScToken(data?.scCookie, cookieOptions)
            } else if (this.scToken) {
              // useApi().customers.setScCookie(this.scToken)
            }
            return (
              data.token && useCartStore().setCart({ tokenValue: data.token })
            )
          },
          onError: () => {
            if (!silent) {
              // navigateTo('/404')
              throw createError({
                statusCode: 404,
                statusMessage: 'Page Not Found'
              })
            }
          }
        })
      }
    },

    updateUserData(data = {}, options) {
      const { $api, $toast } = useNuxtApp()
      const authStore = useAuthStore()
      const customerId = authStore.userId
      let requestBody = data
      console.log('data', data)
      if (!data?.defaultAddress && !data?.defaultRecipient) {
        requestBody = {
          patronymic: this.user.patronymic,
          defaultAddress: this.user.defaultAddress,
          defaultRecipient: this.user.defaultRecipient,
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          birthday: this.user.birthday,
          ...data
        }
      }
      return $api.customers.updateCustomer(customerId, requestBody, {
        onRequest: () => !options?.silent && showLoader(),
        onSuccess: res => {
          if (
            res.user?.phoneNumber !== this.user?.phoneNumber ||
            res?.email !== this.user?.email
          ) {
            useNuxtApp().$eSputnikEvent.customerData(res)
          }
          this.user = transformUserData(res)
          !options?.silent && $toast.success('Успішно')
          if (res.tokens) {
            authStore.setTokens(res.tokens.token, res.tokens['refresh-token'])
          }
        },
        onError: error => options?.onError && options?.onError(error),
        onFinally: () => !options?.silent && hideLoader()
      })
    },
    async changePassword(data, options) {
      const { $api } = useNuxtApp()
      const authStore = useAuthStore()
      const customerId = authStore.userId
      try {
        await $api.customers.changePassword(customerId, data, options)
      } catch (error) {
        console.error(error)
      }
    },
    clearUserData() {
      this.user = null
    },
    clearCarts() {
      if (this.user?.carts) {
        this.user.carts = []
      }
    },
    createWishlist(name = 'Загальний список') {
      let uniqueName = name
      let counter = 1

      while (
        this.wishlists.find(wishlist => wishlist.name === uniqueName) &&
        counter < 1000
      ) {
        counter++
        uniqueName = `${name} ${counter}`
      }
      return useApi().wishlists.create(uniqueName, {
        onRequest: () => showLoader(),
        onSuccess: () => {
          return this.fetchUserData()
        },
        onFinally: () => hideLoader()
      })
    },
    updateWishlist({ token, name }) {
      return useApi().wishlists.updateList(
        { token, name },
        {
          onSuccess: () => {
            this.user.wishlists = this.user.wishlists.map(wishlist => {
              if (wishlist.token === token) {
                wishlist.name = name
              }
              return wishlist
            })
          }
        }
      )
    },
    deleteWishlist(token) {
      return useApi().wishlists.deleteList(token, {
        onSuccess: () => {
          this.user.wishlists = this.user.wishlists.filter(
            wishlist => wishlist.token !== token
          )
          this.fetchUserData(true)
        }
      })
    },
    async addProductToFavorites({ token, productVariantId }, options) {
      if (!token) {
        if (this.wishlists?.length) {
          token = this.wishlists[0]?.token
        } else {
          token = (await this.createWishlist())?.token
        }
      }
      return useApi().wishlists.addVariant(
        { token, productVariantId },
        {
          onSuccess: () => {
            options?.onSuccess && options.onSuccess()
            this.fetchUserData(true)
          }
        }
      )
    },
    async deleteWishlistVariants(token, productVariantIds) {
      await Promise.all(
        productVariantIds.map(productVariantId =>
          useApi().wishlists.deleteVariant({
            token,
            productVariantId
          })
        )
      )
      return this.fetchUserData()
    },
    uploadUserImage(data) {
      return useApi().avatarImages.create(data, {
        onRequest: () => (this.loading = true),
        onFinally: () => (this.loading = false)
      })
    },
    deleteUserImage(options) {
      return useApi().avatarImages.delete({
        onRequest: () => (this.loading = true),
        onSuccess: () => {
          this.fetchUserData()
          options.onSuccess && options.onSuccess()
        },
        onFinally: () => (this.loading = false)
      })
    },
    async connectGoogle(payload, options) {
      const { $api } = useNuxtApp()
      try {
        await $api.socials.connectGoogle(payload, {
          onSuccess: data => {
            this.user?.socialProfiles?.push(data)
            options?.onSuccess && options?.onSuccess()
          },
          onError: error => options?.onError && options?.onError(error.message),
          onFinally: () => options?.onFinally && options?.onFinally()
        })
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async connectFacebook(payload, options) {
      const { $api } = useNuxtApp()
      try {
        await $api.socials.connectFacebook(payload, {
          onSuccess: data => {
            this.user?.socialProfiles?.push(data)
            options?.onSuccess && options?.onSuccess()
          },
          onError: error => options?.onError && options?.onError(error.message),
          onFinally: () => options?.onFinally && options?.onFinally()
        })
      } catch (error) {
        console.error(error)
        return false
      }
    },
    deleteSocialProfile(socialProfile, options = {}) {
      return useApi().delete(socialProfile['@id'], {
        onSuccess: () => {
          this.user.socialProfiles = this.user.socialProfiles.filter(
            v => v['@id'] !== socialProfile['@id']
          )
        },
        ...options
      })
    },
    async getScAuthToken() {
      return await useApi().customers.getScAuthToken({
        onSuccess: res => {
          return res?.token
        }
      })
    }
  },
  getters: {
    carts: state => state?.user?.carts || [],
    getUser: state => state.user,
    email: state => get(state, ['user', 'email']),
    firstName: state => get(state, ['user', 'firstName']),
    scCookieToken: state => state.scToken,
    wishlists: state =>
      get(state, ['user', 'wishlists'], []).map(v => new WishlistEntity(v)),
    totalInWishlists: state => state?.user?.totalInWishlists || 0,
    isLoading: state => state.loading,
    ordersAmount: state => state.user?.totalOrders,
    wishlistsAmount: state => state.wishlists.length,
    socialProfiles: state => get(state, ['user', 'socialProfiles'], []),
    subscriptionList: state => state.user?.subscriptions,
    isExpert: state => state.user?.group?.code === 'expert' || false
  }
})
