import api from '../api.client'

const path = '/api/v2/shop/customers'
const subscribePath = '/api/v2/shop/customer-subscriptions'

export default {
  createCustomer: (data, options) => api(options).post(path, data),
  getCustomer: (id, options) => api(options).get(`${path}/${id}`),
  getShortInfo: options => api(options).get(`${path}/short-info `),
  getBonuses: (customerId, options) =>
    api(options).get(`${path}/${customerId}/bonuses`),
  updateCustomer: (id, data, options) =>
    api(options).put(`${path}/${id}`, data),
  changePassword: (id, data, options) =>
    api(options).put(`${path}/${id}/password`, data),
  getExperts: options =>
    api({ auth: false, ...options }).get('/api/v2/shop/experts'),
  subscribe: (data, options) => api(options).post(subscribePath, data),
  unSubscribe: (subscriptionType, options) =>
    api(options).delete(`${subscribePath}/${subscriptionType}`),
  setDiscountCard: (id, data, options) =>
    api(options).put(`${path}/${id}/set-discount-card`, data),
  activateDiscountCard: (id, data, options) =>
    api(options).put(`${path}/${id}/activate-discount-card`, data),
  setScCookie: (cookie, options) =>
    api(options).post(`${path}/cookie/sc`, { cookie }),
  getScAuthToken: options => api(options).get(`${path}/esputnik-auth-token`)
}
