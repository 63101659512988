<template>
  <Popover v-slot="{ open, close }" class="w-fit relative">
    <PopoverButton
      class="cursor-default focus:outline-none"
      @mouseover="e => hoverPopover(e, open)"
      @mouseleave="closePopover(close)"
    >
      <slot name="target"></slot>
    </PopoverButton>

    <template v-if="props.id">
      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
      >
        <PopoverPanel
          class="absolute left-full top-0 z-10 w-auto min-w-[15rem] -translate-x-1/2 transform p-6 ml-24"
          @mouseover.prevent="popoverHovered = true"
          @mouseleave.prevent="closePopover(close)"
        >
          <div
            class="overflow-hidden rounded shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div class="relative min-w-[310px] bg-white p-6">
              <p
                class="text-xs leading-4 indent-3 text-justify"
                v-html="information"
              ></p>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </template>
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

const props = defineProps({
  id: {
    type: String,
    default: ''
  }
})

const popoverHovered = ref(false)
const popoverTimeout = ref()
const information = ref('')
const isFetching = ref(false)

const hoverPopover = async (e, open) => {
  if (!props.id) {
    return false
  }

  try {
    !information.value &&
      !isFetching.value &&
      (await useApi().brands.getOne(props.id, {
        onRequest: () => (isFetching.value = true),
        onSuccess: ({ description }) => {
          information.value = description
        },
        onFinally: () => (isFetching.value = false)
      }))
  } catch {
    return false
  }

  if (!information.value) {
    return false
  }

  popoverHovered.value = true
  if (!open) {
    e.target.parentNode.click()
  }
}

const closePopover = close => {
  popoverHovered.value = false
  if (popoverTimeout.value) {
    clearTimeout(popoverTimeout.value)
  }
  popoverTimeout.value = setTimeout(() => {
    if (!popoverHovered.value) {
      close()
    }
  }, 100)
}
</script>
