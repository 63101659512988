<template>
  <button
    v-for="(option, index) in sortedOptions"
    :key="`${index}-${option?.code}-${option?.value}`"
    class="relative flex items-center justify-center border-2 rounded-[3px] bg-cover bg-no-repeat hover:border-primary"
    :class="[
      plateSize,
      isSelected(option) ? 'border-primary bg-gray' : 'border-gray',
      !isColor && 'w-auto px-2',
      option?.available ? '' : 'opacity-40 crossed'
    ]"
    :style="getButtonStyle(option)"
    @click.stop.prevent="emits('select', option)"
  >
    {{ getLabel(option) }}
    <div
      v-if="isSelected(option)"
      class="flex items-center justify-center w-4 h-4 bg-primary absolute -top-2 -right-2 rounded-full"
    >
      <IconsProductPickerCheck class="w-[6px] h-1 text-white" />
    </div>
  </button>
</template>
<script setup>
const img = useImage()
const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },
  value: {
    type: [Object, String],
    default: () => {}
  },
  label: {
    type: String,
    default: null
  },
  type: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'md'
  },
  isParentCode: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['select'])
const isColor = computed(() => props.type === 'color')
const plateSize = computed(() =>
  props.size === 'sm' ? 'w-[34px] h-[34px]' : 'w-11 h-11'
)

const uniqByWithAvailable = (arr, keyFn) => {
  const seen = new Map()

  arr.forEach(item => {
    const key = keyFn(item)
    const existingItem = seen.get(key)

    // Додаємо новий елемент, якщо ключа немає в мапі або замінюємо, якщо новий елемент available
    if (!existingItem || (!existingItem.available && item.available)) {
      seen.set(key, item)
    }
  })

  return Array.from(seen.values())
}

const addSuffixToDuplicates = arr => {
  const countMap = {}

  arr.map(item => {
    if (countMap[item.value]) {
      countMap[item.value]++
      item.displayValue = `${item.value} #${countMap[item.value]}`
      return item
    } else {
      countMap[item.value] = 1
      item.displayValue = item.value
      return item
    }
  })
  return arr
}

const getLabel = option =>
  isColor.value ? '' : option?.displayValue || option?.value
/* сортування картка плитка */
const sortedOptions = computed(() => {
  return props.isParentCode && props.type === 'color'
    ? uniqByWithAvailable(sortOptionsVariants(props.options), v => v?.code)
    : addSuffixToDuplicates(sortOptionsVariants(props.options))
})

const getButtonStyle = option => {
  if (!isColor.value) {
    return ''
  }
  if (option?.media?.path) {
    const imgUrl = img(useAssetUrl(option?.media?.path), { width: 44 })
    return `background: url("${imgUrl}")`
  }
  if (option.displayValue) {
    return `background-color: ${option?.displayValue?.toUpperCase()}`
  }
}

const isSelected = option => {
  if (props.isParentCode && props.type === 'color') {
    return option.code === props?.value
  }
  return option?.slug === useRoute().params?.slug
}
</script>

<style scoped>
.crossed {
  position: relative;
}
.crossed::before {
  position: absolute;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 18L18 6M6 6l12 12'/%3E%3C/svg%3E");
  width: 50px;
  height: 50px;
}
</style>
