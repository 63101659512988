<template>
  <div
    class="relative xl:space-x-2.5 space-y-2.5 xl:space-y-0 flex flex-col xl:flex-row xl:min-h-min min-h-[324px]"
  >
    <div class="w-auto xl:w-[66.7%] relative xl:h-full promo-banner-wrapper">
      <swiper
        :slides-per-view="1"
        :modules="modules"
        :navigation="{
          nextEl: mainSliderButtonRight,
          prevEl: mainSliderButtonLeft
        }"
        @swiper="getRef"
        @slide-change="slideChange"
      >
        <swiper-slide
          v-for="(promo, index) of banners"
          :key="promo.id"
          class="h-full"
        >
          <div
            class="h-full cursor-pointer w-full h-full min-h-[190px] min-lg:max-h-[380px] xl:max-h-[450px] 2xl:max-h-[480px]"
            @click="onPromoClick(promo)"
          >
            <nuxt-img
              :data-url="promo?.url"
              :src="promo.imageLandscape"
              class="w-full h-full min-h-[190px] min-lg:max-h-[380px] xl:max-h-[450px] 2xl:max-h-[480px] lg:object-contain aspect-promo"
              sizes="xs:320px sm:640px md:800px xl:1280px"
              format="webp"
              fit="cover"
              width="1024"
              height="480"
              :placeholder="[50, 25]"
              :loading="!index ? 'eager' : 'lazy'"
              :preload="!index"
              :alt="promo?.name"
            />
            <!-- <div class="swiper-lazy-preloader"></div> -->
          </div>
        </swiper-slide>
      </swiper>

      <div
        class="absolute -left-1.5 min-h-[214px] inset-y-0 z-1 flex md:hidden"
      >
        <button
          type="button"
          class="rounded-full m-auto bg-[white] h-[20px] w-[20px] shadow-md flex justify-center items-center border border-gray border-solid"
          @click="slideList(true)"
        >
          <IconsArrowLeft class="text-green-ibis h-2.5 w-2.5" />
        </button>
      </div>

      <div
        class="absolute -right-1.5 min-h-[214px] inset-y-0 z-1 flex md:hidden"
      >
        <button
          type="button"
          class="rounded-full m-auto bg-[white] h-[20px] w-[20px] shadow-md flex justify-center items-center border border-gray border-solid"
          @click="slideList()"
        >
          <IconsArrowRight class="text-green-ibis h-2.5 w-2.5" />
        </button>
      </div>

      <div class="flex gap-[7px] min-h-[12px] mt-4 justify-center md:hidden">
        <div
          v-for="index in banners?.length"
          :key="index"
          class="w-5 h-[4px] rounded bg-green-swamp max-w-5"
          :class="[currentSlide === index - 1 ? 'opacity-100' : 'opacity-30']"
        ></div>
      </div>

      <div class="hidden md:block">
        <button
          ref="mainSliderButtonLeft"
          type="button"
          class="rounded p-[15px] h-11 w-11 flex justify-center items-center absolute bottom-1/2 translate-y-1/2 z-[100] desktop-navigation-button desktop-navigation-button--left"
        >
          <IconsTriangleArrowLeft />
        </button>
        <button
          ref="mainSliderButtonRight"
          type="button"
          class="rounded p-[15px] h-11 w-11 flex justify-center items-center absolute bottom-1/2 translate-y-1/2 z-[100] desktop-navigation-button desktop-navigation-button--right"
        >
          <IconsTriangleArrowRight />
        </button>
      </div>
      <div
        class="absolute z-1 right-5 left-5 bottom-5 items-end hidden md:flex"
      >
        <div class="flex space-x-5 mx-auto justify-center w-full">
          <div
            v-for="index in banners?.length"
            :key="index"
            class="indicator h-[3px] rounded bg-white"
            :class="[currentSlide === index - 1 ? 'opacity-100' : 'opacity-30']"
          ></div>
        </div>
        <!-- <div class="flex flex-row space-x-px"> -->
        <!-- </div> -->
      </div>
    </div>
    <div
      class="space-x-2.5 xl:space-x-0 xl:space-y-2.5 flex flex-row xl:flex-col flex-1 justify-between"
    >
      <template v-for="banner in smallBanners.slice(0, 2)" :key="banner.id">
        <div
          class="cursor-pointer w-1/2 xl:w-full min-h-[95px] md:h-min md:max-h-[230px]"
          @click="onPromoClick(banner)"
        >
          <nuxt-img
            :data-url="promo?.url"
            :src="banner?.imageLandscape"
            class="object-contain min-h-[95px] md:h-min md:max-h-[230px] aspect-promo-small"
            height="232"
            width="485"
            format="webp"
            :placeholder="[50, 25]"
            :alt="banner.name"
            preload
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import 'swiper/css'
import 'swiper/css/virtual'
import Collection from '~/entities/Collection'
const { $gtagEvent } = useNuxtApp()

const modules = [SwiperNavigation, SwiperVirtual]

function getRef(swiperInstance) {
  swiperEl.value = swiperInstance
}
const mainSliderButtonLeft = ref(null)
const mainSliderButtonRight = ref(null)
const swiperEl = ref(null)
const currentSlide = ref(0)

const slideList = left => {
  const instance = swiperEl.value
  left ? instance.slidePrev() : instance.slideNext()
}

const slideChange = instance => {
  currentSlide.value = instance.activeIndex
}
const onPromoClick = promo => {
  $gtagEvent.bannerClick(promo?.url)
  setTimeout(() => {
    return (
      promo?.url && navigateTo(useLocalePath()(promo?.url), { external: true })
    )
  }, 3000)
}

const { data: images } = useAsyncData('promo-banners-home', () => {
  return useApi()
    .banners.getMainPage()
    .then(res => new Collection(res)?.data)
})

const banners = computed(() =>
  images.value
    ? images.value?.filter(({ type }) => type.code === 'main_page')
    : [
        { id: 'placeholder-1', imageLandscape: NO_IMAGE_PATH },
        { id: 'placeholder-2', imageLandscape: NO_IMAGE_PATH }
      ]
)

const smallBanners = computed(() =>
  images.value
    ? images.value?.filter(({ type }) => type.code === 'main_page_right')
    : [
        { id: 'placeholder-1', imageLandscape: NO_IMAGE_PATH },
        { id: 'placeholder-2', imageLandscape: NO_IMAGE_PATH }
      ]
)
</script>

<style scoped lang="postcss">
.promo-banner-wrapper .desktop-navigation-button {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.promo-banner-wrapper:hover .desktop-navigation-button {
  opacity: 1;
}
.promo-banner-wrapper .desktop-navigation-button--left {
  left: -0.5rem;
}
.promo-banner-wrapper:hover .desktop-navigation-button--left {
  left: 0.5rem;
}

.promo-banner-wrapper .desktop-navigation-button--right {
  right: -0.5rem;
}
.promo-banner-wrapper:hover .desktop-navigation-button--right {
  right: 0.5rem;
}

.indicator {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 2.5rem;
  min-width: 3px;
}
</style>
